<template>
  <div
    v-if="currentBasket.length > 0 || paymentSuccess"
    class="d-flex flex-column"
    style="height: 100%;width:100%; overflow-y: auto;"
  >
    <div
      v-if="!paymentSuccess" 
      style="width:100%;height:60px;flex-grow: 0;"
      class="mb-3 mb-lg-4"
    >
      <div
        class="text-center mb-2"
        style="font-size: clamp(1rem, 2vw, 1.3rem)"
      >
        CHECKOUT
      </div>
      <div class="d-flex justify-content-center border-center" style="justify-content: space-between !important;">
        <CircleNavigation
          v-for="(pageItem, i) in pageItems"
          :key="i"
          :selected-page="page"
          :action-is-conditional-on="pageItem.actionIsConditionalOn"
          :page="pageItem"
          @selectPage="page = $event"
        />
      </div>
      <!--timeline line-->
      <div class="mx-auto mt-4 navigationTimelineLine" />
    </div>

    <div class="p-2 flex-grow-1">
      <SelectClubOrCharity
        v-if="page === 1"
        @nextPage="checkDuplicates()"
      />

      <Cost
        v-else-if="page === 2"
        @nextPage="goToConfirm"
      />

      <!-- FINAL CONFIRMATION -->
      <div
        v-else-if="page === 3"
        style="font-size: 0.9rem;height: 100%;"
      >
        <!-- SUCCESS -->
        <SuccessfulPaymentMessage
          v-if="paymentSuccess" 
          style="height: 100%"
        />
        <!-- SUCCESS -->
        <div v-else>
          <div
            v-if="confirmMessage.includes('There was a problem processing your entry')"
            style="margin-top:30px;"
          >
            {{ confirmMessage }}
          </div>
          <div v-else style="margin-top: 30px !important;text-align: left;">
            Unfortunately payment was not successful. As such, no money has been taken from your card, and you are not currently in the draw.
            <br><br>
            You can try again by pressing the 'RETRY' button below.
            <br><br>
            <div v-if="confirmMessage">
              Note: the reason we have been given regarding your unsuccessful transaction is as follows: <strong>{{ confirmMessage }}</strong>
            </div>
            <div class="d-flex mt-5" style="margin-top: 30px !important;">
              <mdb-btn
                class="btn primary-btn btn-radius m-0"
                style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
                @click="retryPayment()"
              >
                RETRY
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="height: 100%;width:100%; overflow-y: auto;">
    <div
      style="width:100%;height:60px;flex-grow: 0;"
      class="mb-3 mb-lg-4"
    >
      <div
        class="text-center mb-2"
        style="font-size: clamp(1rem, 2vw, 1.3rem)"
      >
        CHECKOUT
      </div>
      <div class="text-center text-white text-center">
        YOUR CART IS EMPTY
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import CircleNavigation from '@/components/circleNavigation.vue'
import SelectClubOrCharity from './selectAClubOrCharity/selectClubCharity.vue'
import Cost from './cost/cost.vue'
import SuccessfulPaymentMessage from './successfulPaymentMessage.vue'

export default {
  name: 'Checkout',
  components: {
    CircleNavigation, SelectClubOrCharity, Cost, SuccessfulPaymentMessage
  },
  data () {
    return {
      checkOutProcessStep: 1,
      page: 1,
      setSelectedClubId: 0,
      paymentSuccess: false,
      confirmMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'currentBasket',
      'userData'
    ]),
    pageItems () {
      return [
        { index: 1, isCurrent: this.page === 1, text: 'Club', actionIsConditionalOn: !this.displayedOnPlayPage },
        { index: 2, isCurrent: this.page === 2, text: 'Payment', actionIsConditionalOn: false },
        { index: 3, isCurrent: this.page === 3, text: 'Confirm', actionIsConditionalOn: false }
      ]
    },
    displayedOnPlayPage () {
      return this.$route.path === '/play/checkout'
    },
    basketTicketNumbers () {
      let ticketNumbers = []
      this.currentBasket.map(i => {
        ticketNumbers.push(`${i.Colour}${i.FirstInitial}${i.SecondInitial}${i.Hero}${i.Number}${i.Sport}`)
      })
      return ticketNumbers
    }
  },
  created () {
    this.checkIfRedirect()
    if (!this.userData.aut) {
      this.$router.push('/basket/auth')
      this.$store.commit('storePanelViewedOnMobile', 'Right')
    }
    this.$store.commit('storeClub', { ClubId: 0 })
  },
  methods: {
    checkIfRedirect () {
      // only satisfied if we're redirecting after successful payment to '/play/checkout'
      if (this.$route.query.imp) {
        this.page = 3
        this.paymentSuccess = true
      }
    },
    goToCost () {
      this.$store.commit('CLOSE_GENERIC_CONFIRM_MODAL')
      this.page = 2
    },
    checkDuplicates () {
      let duplicateCount = 0
      this.basketTicketNumbers.forEach(i => {
        let firstIndex = this.basketTicketNumbers.findIndex(s => s == i)
        let secondIndex = this.basketTicketNumbers.findLastIndex(s => s == i)
        if (firstIndex != -1 && secondIndex != -1 && firstIndex != secondIndex) {
           duplicateCount++
        }
      })
      if (duplicateCount == 0) {
        this.goToCost()
      } else {
        this.openDuplicateCheckModal()
      }
    },
    openDuplicateCheckModal () {
      var msg = 'You currently have some duplicate hero entries. Are you sure you want to continue?'
      this.$store.commit('GENERIC_CONFIRM_MODAL', {
        showing: true,
        confirmTitle: 'DUPLICATE HEROES',
        confirmMessage: msg,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.goToCost()
      })
    },
    goToRoute (route) {
      this.$router.push(route).catch(() => {})
    },
    goToConfirm ({ success, message, immediatePayment }) {
      this.$store.commit('storeClub', { ClubId: 0 })
      if (success) {
        this.$router.push({ path: '/play/checkout', query: { imp: immediatePayment ? '0' : '1', page: 1 } })
        this.$store.commit('storePanelViewedOnMobile', 'Right')
      } else {
        this.page = 3
        this.paymentSuccess = false
        this.confirmMessage = message
      }
    },
    retryPayment () {
      this.checkOutProcessStep = 1
      this.page = 1
      this.$store.commit('storeClub', { ClubId: 0 })
      this.paymentSuccess = false
      this.confirmMessage = ''
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .scrollContainerSignUp {
    float:left;
    height: calc(100vh - 380px);
    width:100%;
    padding:3px;
  }
  .scrollContentSignUp {
    height: calc(100vh - 310px);
    overflow:auto;
    background:#fff;
  }
}
</style>
<style>
  label[for="st-card-number-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
  label[for="st-expiration-date-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
  label[for="st-security-code-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
  iframe[id^=st-] {
    height: 100px;
    border: 0;
    width:100%;
  }
  .card-style {
    display: block !important;
    font-size: 10px !important;
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    outline: none !important;
  }
</style>
