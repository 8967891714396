<template>
  <div class="pt-2 pt-md-3 text-center">
    <div
      class="mb-2"
      style="font-size: 0.9rem;margin-top:20px;"
    >
      PLEASE ADD YOUR DEBIT CARD
    </div>
    <div v-if="userData.crd === 0">
      <div v-if="immediatePayment" style="font-size: 0.9rem; margin-bottom:20px;">
        You will be charged £{{ basketCost }} now and £{{ basketCost }} per month thereafter {{ basketCost === 5 ? 'for your hero.' : 'for all your heroes.' }}
        <br><br>
        <span style="color:white;">
          Do not refresh  your browser after pressing the "PAY" button, it may take upwards of 30 seconds to process your details.
        </span>
      </div>
      <div v-else style="font-size: 0.9rem; margin-bottom:20px;">
        You will be charged £{{ basketCost }} at the start of next month and £{{ basketCost }} per month thereafter {{ basketCost === 5 ? 'for your hero.' : 'for all your heroes.' }}
        <br><br>
        <span style="color:white;">
          Do not refresh  your browser after pressing the "PAY" button, it may take upwards of 30 seconds to process your card details.
        </span>
      </div>
    </div>
    <div v-else>
      <span style="color:white;">
        Do not refresh  your browser after pressing the "PAY" button, it may take upwards of 30 seconds to process your card details.
      </span>
    </div>
    <div id="stPayment">
      <form
        id="st-form"
        :action="returnVal"
        method="POST"
        style="width:100% important;"
      >
        <div style="padding:0px;">
          <div id="st-card-number" style="margin-top:10px;margin-bottom: -15px!important;" />
          <div id="st-expiration-date" style="margin-bottom: -15px!important;" />
          <div id="st-security-code" style="margin-bottom: -20px!important;" />
        </div>
        <div style="margin-top:20px;font-size:0.8rem;">
          <mdb-btn
            id="st-form__submit"
            type="submit"
            class="btn primary-btn btn-radius m-0"
            style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          >
            PAY
          </mdb-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API_URL } from '@/common/config'

export default {
  name: 'TrustPaymentCheckout',
  props: {
    immediatePayment: Boolean
  },
  data () {
    return {
      returnVal: API_URL + '/api/game/transaction'
    }
  },
  computed: {
    ...mapGetters([
      'currentBasket',
      'mobileView',
      'userData'
    ]),
    basketCost () {
      return this.currentBasket.length * 5
    }
  }
}
</script>
<style>
  label[for="st-card-number-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
  label[for="st-expiration-date-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
  label[for="st-security-code-input"] {
    font-size: 16px;
    color: var(--pr-color) !important;
  }
</style>
