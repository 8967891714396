<template>
  <div v-if="showPage">
    <div class="primary-colour my-3 my-xl-4 text-center" style="font-size: 0.9rem;margin-top:30px !important;margin-bottom: 20px !important;">
      WHEN DO YOU WANT TO START PLAYING?
    </div>
    <div
      v-for="(option, i) in options"
      :key="i"
      :class="selectedOption.index == option.index || hoveringOption.index == option.index ? 'selectedOption' : 'nonSelectedOption'"
      class="initialOption p-2 p-lg-3 mb-2 mb-lg-3 text-center cp"
      style="border-radius: 5px;margin-bottom: 30px !important;"
      @mouseover="hoveringOption = option"
      @mouseleave="hoveringOption = {}"
      @click="selectedOption = option"
    >
      <div class="mx-auto" style="font-size: 0.9rem; border-bottom: var(--pr-color) 1px solid; width: fit-content;margin-bottom:10px;">
        {{ option.title }}
      </div>
      <div style="font-size: 0.9rem;margin-bottom:20px;" class="font-weight-light">
        {{ option.first }}
      </div>
      <div style="font-size: 0.9rem;" class="font-weight-light">
        {{ option.second }}
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <mdb-btn
        v-if="selectedOption.index !== -1"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="$emit('nextCostStep', selectedOption.index)"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'WhenToStartPaying',
  data () {
    return {
      selectedOption: { index: -1 },
      hoveringOption: {},
      draws: {
        DrawsRemaining: 1
      },
      firstDrawNextMonth: null,
      nextDraw: null,
      showPage: false,
      first: ''
    }
  },
  computed: {
    ...mapGetters(['currentBasket', 'userData']),
    basketCost () {
      return this.currentBasket.length * 5
    },
    immediateFirstSentence () {
      var cost = this.currentBasket.length * 5
      var text = 'You will be charged £' + cost + ' now and entered into the next'
      if (this.userData.crd > 0) {
        if (this.userData.crd >= this.currentBasket.length) {
          if (this.userData.crd >= this.currentBasket.length) {
            if (this.currentBasket.length === 1) {
              text = 'Due to having credits, you have a free entry. We will now enter you into the next'  
            } else {
              text = 'Due to having credits, your entries, will be free. We will now enter you into the next'  
            }
          } else if (this.userData.crd === 1) {
            var x = (this.currentBasket.length - 1) * 5
            text = 'Due to having credits you have one free entry. For your remaining entries you will be charged £' + x + '. You will now be entered into the next'  
          }
        }
        if (this.userData.crd < this.currentBasket.length) {
          var y = (this.currentBasket.length) * 5 - (this.userData.crd * 5)
          text = 'Due to having credits, you will only be charged £' + y + ' and will be immediately entered into the next'  
        }
      }
      return text
    },
    immediateSecondSentence () {
      var text =  'On the first of each following draw month you will be charged £' +  this.basketCost + '.'
      if (this.userData.crd > 0) {
        text = 'On the first of each following draw month, you will be charged £5 per hero unless you have any additional credits remaining.'
      }
      return text
    },
    nextMonthSentence () {
      var cost = this.currentBasket.length * 5
      var text = 'On the first of each draw month you will be charged £' + cost + ' for your entries.'
      if (this.userData.crd > 0) {
        if (this.userData.crd >= this.currentBasket.length) {
          if (this.userData.crd > 1) {
            if (this.currentBasket.length === 1) {
              text = 'Due to having credits, your entry, starting next month, will be free.'  
            } else {
              text = 'Due to having credits, your entries, starting next month, will be free.'  
            }
          } else {
            text = 'Due to having credits, you will have a free entry, starting next month.'  
          }
        }
        if (this.userData.crd < this.currentBasket.length) {
          var y = (this.currentBasket.length) * 5 - (this.userData.crd * 5)
          text = 'Due to having credits, you will only be charged £' + y + ' next month.'  
        }
      }
      return text
    },
    options () {
      const option = [
        {
          index: 0,
          title: 'Immediately',
          first: `${this.immediateFirstSentence} ${this.draws.DrawsRemaining === 1 ? 'draw' : this.draws.DrawsRemaining + ' draws'}, starting ` + this.nextDraw +  `.`,
          second: `${this.immediateSecondSentence}`
        },
        {
          index: 1,
          title: 'From Next Draw Month',
          first: `${this.nextMonthSentence}`,
          second: `Your first draw will be on ${this.firstDrawNextMonth}.`
        }
      ]
      return option
    }
  },
  created () {
    this.showPage = false
    this.getDraws()
  },
  methods: {
    getDraws () {
      baseService.getDrawsThisMonth(this.userData.access_token).then((res) => {
        this.draws = res.data
        if (this.draws.DrawsRemaining === 0) {
          this.$emit('nextCostStep', 1)
          return
        }
        const fdnm = new Date(this.draws.FirstDrawNextMonth)
        const nd = new Date(this.draws.NextDraw)
        this.firstDrawNextMonth = fdnm.toLocaleString('default', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })
        this.nextDraw = nd.toLocaleString('default', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })
        this.showPage = true
      })
    }
  }
}
</script>
